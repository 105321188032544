<script>
import { mapGetters, mapState, mapActions } from "vuex";
const trayLogin = () => import("tray-login/dist/trayLogin.umd.min");
import AppModal from "@/domains/WaitingList/components/modal";
import { Modal } from "bootstrap";
import validadeAmbient from "@/mixins/validadeAmbient";
import AppAlert from "@/features/bootstrap/Alert";
import { sanitize } from "dompurify";

export default {
  name: "AppLogin",

  mixins: [validadeAmbient],

  data() {
    return {
      loadingLogin: "app__loading",
      hrefWithParams: "",
      firstContainerBorder: "border-right: #C4C4C4 2px solid;",
      firstContainerBorderMobile: "border-bottom: #C4C4C4 2px solid;",
      errorPostLogin: false,
      showTrayLogin: false,
      modalDataError: {},
      message: {
        title: {
          icon: "",
          text: "",
        },
        variant: "",
        text: "",
        loading: true,
      },
    };
  },

  mounted() {
    this.executeExternalScripts();
  },

  created() {
    /**
     * Salva os parametros da URL ao carregar a página
     */
    this.hrefWithParams = window.location.href;

    /**
     * Evento para identificar erro no post de login
     */
    window.addEventListener("errorPostLogin", () => {
      this.errorPostLogin = true;
    });

    if (this.store_info.name !== "") {
      this.stopLoadingLogin();
      this.loadTrayLogin();
      this.setExclusiveAccessAlert();
      this.executeExternalScripts();
    }
  },

  components: {
    AppModal,
    AppAlert,
  },

  computed: {
    ...mapGetters(["isMobile", "langs", "dataCallbackPost"]),
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),

    /**
     * Verifica se existe descrição personalizada no localstorage
     * @return {bool}
     */
    hasCustomTextInLocalStorage() {
      return !!localStorage.getItem("customDescriptionAlertLogin");
    },

    /**
     * Recupera do localstorage a descrição personalizada, contendo:
     * titulo e descrição
     * @return {object}
     */
    getCustomText() {
      const { title, description } = JSON.parse(
        localStorage.getItem("customDescriptionAlertLogin")
      );

      return {
        title: sanitize(title),
        description: sanitize(description),
      };
    },

    /**
     * Recupera o estado de loading da mensagem do alert
     * @return {bool}
     */
    alertLoading() {
      return this.message.loading;
    },

    /**
     * Valida se a aplicação foi aberta em um dispositivo mobile
     * e retorna diferentes classes
     * @return {string}
     */
    mobileStyle() {
      return this.isMobile ? "app__login__mobile" : "app__login";
    },

    /**
     * Aplica uma classe de estilo diferente caso a loja exiba
     * seus produtos apenas após o login
     */
    productsAfterLoginStyle() {
      return this.showProductsAfterLogin() && "app__login__products-afer-login";
    },

    /**
     * Valida se a toggle de tela de login está ativa na página
     * @return {bool}
     */
    hasToggleLogin() {
      const { toggle_redirect_to_new_login_page } =
        this.store_info.settings.store;
      return (
        toggle_redirect_to_new_login_page &&
        toggle_redirect_to_new_login_page === "1"
      );
    },

    /**
     * Redireciona para a antiga tela de login
     * @return {Promisse}
     */
    redirectToOldLogin() {
      return window.location.replace(
        `${window.location.origin}/loja/login_layout.php?origem=central`
      );
    },

    /**
     * Retorna a rota para a home dependendo do ambiente
     * @return {string}
     */
    homePath() {
      return window.location.pathname.split("/")[1];
    },

    /**
     * Valida se foi feito um login social
     * @return {bool}
     */
    isSocialLogin() {
      try {
        const url = this.hrefWithParams.split("?");
        const urlSearchParams = new URLSearchParams(url?.[1]);
        const params = Object.fromEntries(urlSearchParams);
        return params?.social === "1";
      } catch {
        return false;
      }
    },

    /**
     * Retorna o estilo da borda que será usado no container de login
     * Varia se o site for aberto em desktop ou aparelho mobile
     * @return {string}
     */
    styleFirstContainer() {
      return this.isMobile
        ? this.firstContainerBorderMobile
        : this.firstContainerBorder;
    },

    /**
     * Modal de erro de login
     * @return {Modal}
     */
    loginErrorModal() {
      return new Modal(document.getElementById("exampleModal"));
    },

    /**
     * Verifica se o termo pode ser exibido.
     *
     * @return {bool}
     * */
    isValidTerm() {
      const acceptedTerms = {
        privacy: "política de privacidade",
        use: "termos de uso",
      };

      const terms = this.store_info.terms?.toLowerCase();

      const isValid =
        terms.includes(acceptedTerms.privacy) ||
        terms.includes(acceptedTerms.use);

      return isValid;
    },

    /**
     * Retorna o conteúdo do termo a ser exibido.
     *
     * @return {string}
     * */
    loginTerm() {
      return this.isValidTerm ? this.store_info.terms : "";
    },

    /**
     * Obtem os dados de login recebidos via query params e retorna um objeto com eles
     *
     * @return {object}
     */
    getQueryParams() {
      const paramsArray = window.location.search.substring(1).split("&");

      const paramsObject = paramsArray.reduce((params, param) => {
        let [key, value] = param.split("=");
        params[key] = String(value);
        return params;
      }, {});

      return paramsObject;
    },
  },

  methods: {
    ...mapActions(["setDataCallbackPost"]),

    /**
     * Deve aguardar o tempo de execução dos scripts do gtm de acordo com as regras:
     *
     * - se houver a configuração de ver produtos somente após login
     * - e se houver gtm configurado na loja
     * - e se não houver descrição salva no localstorage
     *
     * @return {void}
     */
    async executeExternalScripts() {
      const scriptExecutionDelay = 750;

      if (
        !this.showProductsAfterLogin() ||
        !this.hasGoogleTagManagerScript() ||
        this.hasCustomTextInLocalStorage
      ) {
        this.message.loading = false;
        return;
      }

      await this.delay(scriptExecutionDelay);
      this.message.loading = false;
    },

    /**
     * Atrasa a execução do código por um período de tempo especifico
     * @param {number} delayTime tempo para atrasar a execução em milissegundos.
     * @returns {Promise<void>}
     */
    async delay(delayTime) {
      try {
        await new Promise((resolve) => setTimeout(resolve, delayTime));
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Valida se há token salvo no localStorage
     * @param {string} token valor do token do localStorage
     * @return {bool}
     */
    hasToken(token) {
      return token && token !== null && token !== "false";
    },

    /**
     * Verifica se foi feito um login social e se há token
     * @param {string} token valor do token do localStorage
     * @return {bool}
     */
    hasSocialToken(token) {
      return this.isSocialLogin && this.hasToken(token);
    },

    /**
     * Redireciona para a tela de cadastro
     * @return {Promisse}
     */
    redirectToRegister() {
      this.setLoadingLogin("app__loading");
      return window.location.replace(
        this.mountStoreUrl("loja/cadastro_layout.php")
      );
    },

    /**
     * Caso o componente tray-login tenha sido carregado, altera seu valor de exibição
     * @return {undefined}
     */
    toggleTrayLogin() {
      const trayLogin = document.querySelector("tray-login");

      if (trayLogin) {
        const isDisplayNone = trayLogin.style.display === "none";
        trayLogin.style.display = isDisplayNone ? "block" : "none";
      }
    },

    /**
     * Constrói um link com a base da URL da loja e a continuação passada por parametro
     * @param {string} path string com o caminho da URL
     * @return {string}
     */
    mountStoreUrl(path) {
      return `${window.location.origin}/${path}`;
    },

    /**
     * Faz validações no token
     * @param {string} token valor do token do localStorage
     * @return {undefined}
     */

    async validateToken(token) {
      /**
       * Valida se é um login social
       */
      if (this.hasSocialToken(token)) {
        await this.addSocialParamsToCallback(token);
        this.loadTrayLogin();
        return;
      }

      /**
       * Valida se o token não tem valor
       */
      if (!this.hasToken(token)) {
        this.setLoadingLogin(false);
        this.loadTrayLogin();
        return;
      }

      /**
       * Redireciona a tela inicial da central
       */
      window.location.replace(this.mountStoreUrl(this.homePath));
    },

    /**
     * Adiciona os parametros social e token ao dataCallbackPost para realizar o login social
     * @return {undefined}
     */
    addSocialParamsToCallback(token) {
      const newCallbackPost = {
        ...this.dataCallbackPost,
        social: "1",
        token,
      };

      this.setDataCallbackPost({
        list: newCallbackPost,
      });
    },

    /**
     * Atribui o valor de showTrayLogin pa true para que carregue o tray login no template
     * Atribui display none ao tray login após carrega-lo
     * @return {undefined}
     */
    loadTrayLogin() {
      this.showTrayLogin = true;
      trayLogin().then(() => this.toggleTrayLogin());
    },

    /**
     * Abre o modal com mensagem de erro sobre o login
     * @return {undefined}
     */
    openModalLoginError() {
      this.setLoadingLogin(false);
      this.setModalDataError();
      this.loginErrorModal.show();
    },

    /**
     * Atribui o valor ao modal com langs e funções
     * @return {undefined}
     */
    setModalDataError() {
      const dataModal = {
        id: 1,
        title: this.langs.login["facebook-login-modal-title"],
        description: `<p>${this.langs.login["facebook-login-modal-mail-not-found"]}<br/>${this.langs.login["facebook-login-modal-mail-new-registry"]}</p>`,
        buttons: [
          {
            classes:
              "app__button--primary-outline app__login__button app__login__button--modal--primary",
            text: this.langs.product["cancel"],
            function: this.stopLoadingLogin,
          },
          {
            classes:
              "app__button--primary app__login__button app__login__button--modal",
            text: this.langs.login["register"],
            function: this.redirectToRegister,
          },
        ],
      };

      this.modalDataError = dataModal;
    },

    /**
     * Faz com que o loading da página pare
     * @return {undefined}
     */
    stopLoadingLogin() {
      this.setLoadingLogin(false);
    },

    /**
     * Altera o loadingLogin pelo valor passado por parametros
     * @param {string} value novo valor do loadingLogin
     * @return {undefined}
     */
    setLoadingLogin(value) {
      this.loadingLogin = value;
    },

    /**
     * Valida se as langs carregaram e se houve erro no login
     * @return {bool}
     */
    canOpenModal(hasError) {
      return hasError && Object.keys(this.langs.login).length > 0;
    },

    /**
     * Recupera os dados de necessários para fazer o login
     * @param {object} storeInfo dados da loja
     * @return {undefined}
     */
    setLoginParams(storeInfo) {
      const newCallbackPost = {
        ...this.getQueryParams,
        PHPSESSID: `${storeInfo.PHPSESSID}`,
        transID: `${storeInfo.PHPSESSID}`,
        loja: `${storeInfo.store_id}`,
      };

      if (this.mustRedirectToStoreHome()) {
        newCallbackPost.origem = "home";
      }

      this.setDataCallbackPost({
        list: newCallbackPost,
      });
    },

    /**
     * Valida se deve redirecionar a página inicial da loja após o login
     * @return {bool}
     */
    mustRedirectToStoreHome() {
      return (
        this.showProductsAfterLogin() &&
        this.dataCallbackPost.origem === "central"
      );
    },

    /**
     * Valida se deve ser exibido o alert de acesso exclusivo
     * @return {undefined}
     */
    setExclusiveAccessAlert() {
      if (this.showProductsAfterLogin()) {
        this.createAlertMessage();
      }
    },

    /**
     * Cria a mensagem de alerta com base em texto personalizado armazenado em localStorage,
     * ou no texto padrão se não houver.
     * @return {void}
     */
    createAlertMessage() {
      const hasCustomText = this.hasCustomTextInLocalStorage;

      const titleText = hasCustomText
        ? this.getCustomText.title
        : this.langs.login["header-alert-login-product"];

      const descriptionText = hasCustomText
        ? this.getCustomText.description
        : this.langs.login["body-alert-login-product"];

      const isLoading = !hasCustomText;

      this.message = {
        title: {
          icon: "exclamation-triangle",
          text: titleText,
        },
        variant: "warning",
        text: descriptionText,
        loading: isLoading,
      };
    },

    /**
     * Valida se a loja tem gtm configurado
     * @return {bool}
     */
    hasGoogleTagManagerScript() {
      return !!this.store_info.integrations.google.tagmanager;
    },

    /**
     * Valida de a loja exibe os produtos apenas após o login
     * @return {bool}
     */
    showProductsAfterLogin() {
      return this.store_info.settings.store.login_product === "1";
    },
  },

  watch: {
    /**
     * Remove o loading da página quando
     * o valor da URL da loja retornar da API
     * @return {undefined}
     */
    store_info(storeInfo) {
      this.setLoginParams(storeInfo);
      this.setExclusiveAccessAlert();
      this.executeExternalScripts();

      const jwtToken = localStorage.getItem("jwtToken");
      this.validateToken(jwtToken);
    },

    errorPostLogin(hasError) {
      if (this.canOpenModal(hasError)) {
        this.openModalLoginError();
      }
    },
  },
};
</script>

<template>
  <section>
    <div>
      <div class="app__login__alert" v-show="showProductsAfterLogin()">
        <app-alert :messages="[message]" :loading="alertLoading" />
      </div>
      <div :class="[mobileStyle, productsAfterLoginStyle]">
        <span v-if="showTrayLogin">
          <tray-login
            id="tray-login-component"
            data-methods="['password', 'google', 'facebook', 'otp', 'identify']"
            :data-callback-post="JSON.stringify(dataCallbackPost)"
            :data-store="dataCallbackPost.loja"
            :data-terms="loginTerm"
            data-callback=""
            data-session=""
          />
        </span>
        <section
          class="app__login__container"
          :style="this.styleFirstContainer"
        >
          <div>
            <h2 :class="loadingLogin">
              {{ this.langs.login["already-registered"] }}
            </h2>
            <p :class="loadingLogin">
              {{ this.langs.login["inform-your-credentials"] }}
            </p>
            <button
              id="login-button"
              class="app__button--primary app__login__button app__login__button--login"
              :class="loadingLogin"
              @click="toggleTrayLogin"
            >
              {{ this.langs.login["enter"] }}
            </button>
          </div>
        </section>
        <section class="app__login__container">
          <div>
            <h2 :class="loadingLogin">
              {{ this.langs.login["my-first-purchase"] }}
            </h2>
            <p :class="loadingLogin">
              {{ this.langs.login["store-registration"] }}
            </p>
            <button
              id="register-button"
              class="app__button--primary-outline app__login__button app__login__button--register"
              :class="loadingLogin"
              @click="this.redirectToRegister()"
            >
              {{ this.langs.login["register"] }}
            </button>
          </div>
        </section>
      </div>
    </div>
    <app-modal :dataModal="this.modalDataError" />
  </section>
</template>
